import * as React from "react"

import { Flex, Box, Text, Paragraph } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import Link from "../base/link"

export const RelatedProducts = ({ title, products, sx, ...rest }) => {
  return (
    <Box {...rest} sx={{ paddingInline: ["16px", "30px"], ...sx }}>
      <Paragraph
        sx={{
          marginBlockStart: ["0px", "24px"],
          marginBlockEnd: ["24px"],
          marginInline: "0px",
          fontFamily: "inherit",
        }}
      >
        {title ?? "Related products"}
      </Paragraph>
      <Flex sx={{ gap: "1px", flexWrap: "wrap" }}>
        {products.map((product) => {
          return (
            <Box
              key={product.id}
              sx={{
                flex: [
                  "0 0 calc(50% - 1px)",
                  "0 0 calc((100% / 3) - 1px)",
                  "0 0 calc(20% - 1px)",
                ],
                ".related-product-secondary-image": {
                  display: "none",
                },
                "a:hover": {
                  ".related-product-primary-image": {
                    display: "none",
                  },
                  ".related-product-secondary-image": {
                    display: "block",
                  },
                },
              }}
            >
              <Link
                to={
                  product.fullSlug ? product.fullSlug : `/all/${product.handle}`
                }
              >
                <Box
                  className={
                    product.featuredImage ? "related-product-primary-image" : ""
                  }
                >
                  <GatsbyImage image={product.primaryImage?.gatsbyImageData} />
                </Box>
                {product.featuredImage && (
                  <Box className="related-product-secondary-image">
                    <GatsbyImage
                      image={product.featuredImage?.gatsbyImageData}
                    />
                  </Box>
                )}
                <Text
                  sx={{
                    display: "block",
                    fontSize: [12, 14],
                    padding: ["10px 20px", "20px 30px"],
                  }}
                >
                  {product.title}
                </Text>
              </Link>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}
