import styled from "@emotion/styled"
import { navigate } from "gatsby"
import React from "react"
import { Box } from "theme-ui"
import Image from "../base/image"

const Wrapper = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.colorCode ? props.colorCode : "none")};
  border: ${(props) =>
    props.isActive
      ? `3px solid ${props.theme.colors.darkGreen}`
      : `1px solid ${props.theme.colors.cremeWhite}`};
  cursor: pointer;
`

const Color = ({ colorCode, detailed, isActive, to, ...rest }) => {

  return (
    <Wrapper isActive={isActive} colorCode={colorCode} {...rest}>
      {detailed && (
        <Image
          key={detailed.id}
          primary={detailed}
          borderRadius={50}
          onClick={() => navigate(to)}
        />
      )}
    </Wrapper>
  )
}

export default Color
