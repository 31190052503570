import styled from "@emotion/styled"
import React, { useState } from "react"
import { Box, Flex } from "theme-ui"
import Text from "../base/text/text"

const Wrapper = styled(Flex)`
  flex-direction: column;
  height: 100%;
`

const Headers = styled(Flex)`
  justify-content: space-between;

  > div {
    justify-content: center;
  }
  > div:first-of-type {
    justify-content: flex-start;
  }

  > div:last-of-type {
    justify-content: flex-end;
  }
`

const Header = styled(Flex)`
  cursor: pointer;
  flex: 1;
  opacity: ${(props) => (props.active ? "1" : "0.3")};

  .header {
    border-bottom: ${(props) =>
      props.active ? `1px solid ${props.theme.colors.darkGreen}` : "none"};
  }
`

const ContentContainer = styled(Box)`
  width: 100%;
`

const Content = styled(Box)`
  display: ${(props) => (props.visible ? "block" : "none")};

  p {
    font-size: ${(props) => props.theme.fontSizes[2]}px;
  }
`

const TabMenu = ({ data }) => {
  const [current, setCurrent] = useState(data[0])

  return (
    <Wrapper>
      <Headers>
        {data.map((c) => (
          <Header
            key={c.id}
            active={current.id === c.id}
            onClick={() => {
              setCurrent(c)
            }}
          >
            <Text sx={{ fontSize: 3 }} className="header">
              {c.name}
            </Text>
          </Header>
        ))}
      </Headers>
      <ContentContainer mt={2}>
        {data.map((c) => (
          <Content key={c.id} visible={current.id === c.id}>
            {c.content}
          </Content>
        ))}
      </ContentContainer>
    </Wrapper>
  )
}

export default TabMenu
