import styled from "@emotion/styled"
import React from "react"
import Text from "../base/text/text"

const Table = styled.table`
  width: 100%;
  border-top: ${(props) => `1px solid ${props.theme.colors.darkGreen}`};
`

const Row = styled.tr`
  border-bottom: ${(props) => `1px solid ${props.theme.colors.darkGreen}`};
`

const Entry = styled.th``

const SizeGuide = ({ sizeGuide }) => {
  return (
    <>
      {sizeGuide.description?.description && (
        <Text sx={{ fontSize: 1 }} my={2}>
          {sizeGuide.description.description}
        </Text>
      )}
      <Table>
        <tbody>
          {sizeGuide.content.tableData.map((entry, i) => {
            return (
              <Row key={`entry-${i}`}>
                {entry.map((value) => (
                  <Entry key={value}>
                    <Text sx={{ fontSize: 0, fontWeight: 100 }}>
                      {!value ? "Size" : value}
                    </Text>
                  </Entry>
                ))}
              </Row>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

export default SizeGuide
